<template>

    <div class="row">
      <div class="col-lg-4 col-xl-4">
        <div class="card">
          <div class="card-body text-center">

            <b-avatar size="lg" variant="light" class="text-dark avatar-xl font-22"
            >
              <template v-if="driver && driver.name ">
                <span class="">{{ driver.name | initials }}</span>
              </template>
              <template v-else>
                <span class="" >Loading</span>
              </template>



            </b-avatar>

            <h4 class="mt-3 mb-0">{{driver.name}}</h4>
<!--            <p class="text-muted">{{prospectiveDriverAccount ? driver.email : ''}}</p>-->

            <a
                class="text-success mb-2"
            >
              <template v-if="prospectiveDriverAccount">
                {{ prospectiveDriverAccount.status !== 'in-progress' || driver.files.length > 0 ? 'uploading files' : formatStatusDisplay(prospectiveDriverAccount.status) }}
              </template>

            </a>

            <div class="text-left mt-3">
              <div class="table-responsive">
                <table class="table table-borderless table-sm">
                  <tbody>
                  <tr>
                    <th scope="row">Full Name :</th>
                    <td class="text-muted">{{ driver.name }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Mobile :</th>
                    <td class="text-muted">{{ driver.phone }}</td>
                  </tr>

                  <template v-if="driverAccount">
                    <tr>
                      <th scope="row">Email :</th>
                      <td class="text-muted">{{ driverAccount.driver_email || 'N/A' }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Dossier number :</th>
                      <td class="text-muted">{{ driverAccount.dossier || 'N/A' }}</td>
                    </tr>
                  </template>

                  <tr>
                    <th scope="row">Date Registered:</th>
                    <td class="text-muted">{{driver.registeredAt}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Last Login:</th>
                    <td class="text-muted">{{driver.last_login_at || driver.registeredAt }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Enrolment status:</th>
                    <template v-if="driverAccount">
                      <template v-if="driverAccount.on_boarding_status === 'UNCOMPLETED'">
                        <td class="text-danger">{{driverAccount.on_boarding_status}}</td>
                      </template>
                      <template v-else>
                        <td class="text-success">{{driverAccount.on_boarding_status}}</td>
                      </template>

                    </template>
                    <template v-else>
                      <td class="text-danger">{{  'INCOMPLETE' }}</td>
                    </template>

                  </tr>
                  <tr>
                    <th scope="row">Service Type:</th>
                    <td class="text-muted">
                      <div class="badge badge-dark text-white p-1">
                        {{prospectiveDriverAccount && prospectiveDriverAccount.service_type != null ? prospectiveDriverAccount.service_type : 'NOT SELECTED' }}
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr>

            <button class="btn btn-block btn-outline-success" @click="markOnBoardingAsCompleted" v-if="!disableAction"> Mark on boarding as completed </button>

          </div>
          <!-- end card-box -->
        </div>

      </div>
      <!-- end col-->
      <div class="col-lg-8 col-xl-8">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title"> Documents</h5>
            <div class="table-responsive">
              <table class="table table-centered mb-0">
                <thead class="font-13 bg-light text-muted">
                <tr>
                  <th class="font-weight-medium">File Name</th>
                  <th class="font-weight-medium">Status</th>
                  <th class="font-weight-medium">Date uploaded</th>
                  <th
                      class="font-weight-medium text-center"
                  >
                    Action
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="tableData in fileManagerData"
                    :key="tableData.id"
                >
                  <td>
                    <img
                        src="@/assets/images/file-icons/pdf.svg"
                        height="30"
                        alt="icon"
                        class="mr-2"
                    />
                    <a href="javascript:void(0);" class="text-dark">{{
                        tableData.file_type | serviceType
                      }}</a>
                  </td>
                  <td>
                    <span v-if="tableData.status === 'pending'" class="text-info"> Pending </span>
                    <span v-if="tableData.status === 'verified'" class="text-success"> Verified </span>
                    <span v-if="tableData.status === 'declined'" class="text-danger"> declined </span>
                  </td>
                  <td class="text-muted font-13">{{ tableData.createdAt }}</td>

                  <td>
                    <ul class="list-inline table-action m-0">
                      <li class="list-inline-item">
                        <a
                            href="javascript:void(0);"
                            class="action-icon px-1"
                            @click="onViewFileDetailsTapped(tableData.id)"
                        >
                          <i class="fas fa-eye"></i
                          ></a>
                      </li>

                      <li class="list-inline-item">
                        <a
                            href="javascript:void(0);"
                            class="action-icon px-1"
                            @click="generateEnrolmentFileDownloadToken(tableData.id)"
                        >
                          <i class="mdi mdi-cloud-download"></i
                          ></a>
                      </li>
                      <li class="list-inline-item">
                        <a
                            href="javascript:void(0);"
                            class="action-icon px-1"
                            @click="onVerifyBtnTapped(tableData.id, tableData.file_type)"
                        >
                          <i class="fas fa-handshake text-success"></i
                          ></a>
                      </li>
                      <li class="list-inline-item">
                        <a
                            href="javascript:void(0);"
                            class="action-icon px-1"
                            @click="onDeclineBtnTapped(tableData.id)"
                        >
                          <i class="fas fa-handshake-slash text-danger"></i
                          ></a>
                      </li>
                      <li class="list-inline-item">
                        <a
                            href="javascript:void(0);"
                            class="action-icon px-1"
                            @click="onRemoveFileBtnTapped(tableData.id, tableData.file_type)"
                        >
                          <i class="fas fa-trash text-info"></i
                          ></a>
                      </li>
                    </ul>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- end card-box-->
        </div>

        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Upload new document for {{driver.name}}</h5>

            <form @submit.prevent="handleAddNewDocumentSubmitted" action="">
              <div class="form-group">
                <label for="file-type"> Select document type </label>
                <select name="" :disabled="disableAction"
                        id="file-type" class="form-control"
                        v-model="addDocumentForm.fileType"
                        :class="{'is-invalid' : addDocumentForm.submitted && $v.addDocumentForm.fileType.$error }"
                >
                  <option value="">-- select ---</option>
                  <option value="internship_document">Internship Document</option>
                  <option value="kvk">KVK</option>
                  <option value="vog">VOG document</option>
                  <option value="taxi_license">Taxi License</option>
                  <option value="taxi_permit">Taxi Permit</option>
                </select>
                <div class="invalid-feedback" v-if="addDocumentForm.submitted && $v.addDocumentForm.fileType.$error">This field is required</div>
              </div>

              <div class="form-group">
                <vue-dropzone
                    id="file"
                    ref="file"
                    :use-custom-slot="true"
                    :options="fileDropzoneOptions"
                    @vdropzone-max-files-exceeded="fileLimitExceeded"
                >
                  <div class="dz-message needsclick">
                    <i class="h1 text-muted ri-upload-cloud-2-line"></i>
                    <h3>Upload file</h3>
                  </div>
                </vue-dropzone>
              </div>
              <div class="form-group">
                <button type="submit" class="btn btn-info" v-if="!disableAction">Submit</button>
              </div>
            </form>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Update Driver's Details</h4>
            <form action="" @submit.prevent="handleAddOrUpdateDriverDetails">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="dossier">Assign Dossier Number</label>
                    <input type="text"
                           v-model="form2.dossier"
                           class="form-control" id="dossier" name="dossier" placeholder="Assign dossier number here">
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="service">Assign service to driver</label>
                    <select name="service" id="service" class="form-control" v-model="form2.service">
                      <option value="">-- Select Service ---</option>
                      <option value="premium_partner">Premium Partner</option>
                      <option value="light_partner_plus">Light Partner Plus</option>
                      <option value="light_partner">Light Partner</option>
                      <option value="medium_partner">Medium Partner</option>
                    </select>
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <button class="btn btn-success" v-if="!disableAction">Save</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

      </div>
      <!-- end col -->

<!--      Modal for file details -->
      <b-modal
          id="file-info-modal"
          centered
          :title="selectedFile.title"
          title-class="font-18"
          hide-footer
      >
      <table class="table table-striped table-condensed table-borderless">
        <tr>
          <td>Status</td>
          <td>
            <span v-if="selectedFile.status === 'pending'" class="badge badge-info text-white p-1"> Pending </span>
            <span v-if="selectedFile.status === 'verified'" class="badge badge-success text-white p-1"> Verified </span>
            <span v-if="selectedFile.status === 'declined'" class="badge badge-danger text-white p-1"> declined </span>
          </td>
        </tr>
        <tr>
          <td>Date uploaded</td>
          <td>
              {{selectedFile.createdAt}}
          </td>
        </tr>
        <tr>
          <td>Document </td>
          <td>
            <a :href="selectedFile.file_path" target="_blank">Click to view document</a>
          </td>
        </tr>
        <tr>
          <td>Uploaded by</td>
          <td>
              {{selectedFile.uploadedBy}}
          </td>
        </tr>
        <tr>
          <td>Verified by</td>
          <td>
            {{selectedFile.verifiedBy}}
          </td>
        </tr>
        <tr>
          <td>Comment</td>
          <td>
            {{selectedFile.comment}}
          </td>
        </tr>
        <tr>
          <td>Document Owner </td>
          <td>
            {{selectedFile.ownerName}}
          </td>
        </tr>
      </table>
      </b-modal>

<!--      Modal-->
      <b-modal
          id="modal-center"
          centered
          title="Reason for rejection"
          title-class="font-18"
          hide-footer
      >

        <form @submit.prevent="handleSubmit">
          <div class="row">
            <div class="col-md-12">
              <b-form-group>

                <b-form-textarea id="textarea" rows="4" v-model="form.message"
                   :class="{ 'is-invalid': submitted && $v.form.message.$error }"
                ></b-form-textarea>
                <div v-if="submitted && !$v.form.message.error" class="invalid-feedback">This value is required.</div>

              </b-form-group>
              <b-form-group class="text-center">
                <b-button class="btn btn-warning" @click="$bvModal.hide('modal-center')"> Cancel </b-button>
                <b-button type="submit" class="btn btn-success m-l-5 ml-1"> Submit </b-button>
              </b-form-group>
            </div>

          </div>
        </form>


      </b-modal>



    </div>

</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import appConfig from "../../../../../app.config";
import {mapActions, mapGetters} from 'vuex';
import {confirm} from "../../../../utils/functions";
import {required} from "vuelidate/lib/validators";
import vue2Dropzone from "vue2-dropzone";

/**
 * Contacts profile component
 */
export default {
  components: {
    Layout,
    PageHeader,
    vueDropzone: vue2Dropzone,
  },
  page: {
    title: "Driver Details",
    meta: [{ name: "description", content: appConfig.description }],
  },
  validations: {
    form: {
      message: {
        required
      },
    },
    addDocumentForm: {
      fileType: {
        required
      },
    }
  },
  data() {
    return {
      addDocumentForm: {
        fileType: '',
        submitted:false
      },
      form: {
        message: '',
        selectedDocumentId: ''
      },
      form2: {
        dossier: '',
        service: ''
      },
      disableAction:false,
      documentFile: {},
      submitted: false,
      driverAccount: null,
      prospectiveDriverAccount: null,
      fileDropzoneOptions: {
        url: '/',
        addRemoveLinks: true,
        maxFilesize: 10,//2mb
        // acceptedFiles: ".jpeg,.jpg,.png,.gif",
        autoProcessQueue: false,
        maxFiles: 1,
      },
    };
  },
  computed: {
    ...mapGetters({
      driver: 'GET_SELECTED_PROSPECTIVE_DRIVER',
      fileManagerData: 'GET_SELECTED_PROSPECTIVE_DRIVER_FILES'
    }),
    selectedFile: {
      get(){
        return this.documentFile
      },
      set(file){
        console.log('selected file', file);
        file['title'] = file ? this.$options.filters.serviceType(file.file_type) : 'Title'
        this.documentFile = file;

      }
    },

  },
  methods: {
    ...mapActions({
      fetchProspectiveDriverDetail: 'fetchProspectiveDriverDetail',
      updateEnrolmentDocument: 'updateEnrolmentDocument'
    }),
    /**
     * Basic Form submit
     */
    checkPermission(){
      const user = this.$store.getters.GET_USER_INFO;
      const user_role = user['roles'].filter((e)=>e.toLowerCase() ==='developer' || e.toLowerCase() ==='super admin')
      if(user_role.length ===0){
        const permission = this.$store.getters.GET_USER_INFO['user_permission_obj'];
        let user_permission = permission.find((e)=>e.name ==='manage drivers')
        if(user_permission){
          if(user_permission['write'] === 1){
            this.disableAction = false;
          }else if(user_permission['write'] === 0){
            this.disableAction = true;
          }
        }
      }
    },

    handleSubmit(e) {
      this.submitted = true
      // stop here if form is invalid
      this.$v.form.$touch()

      if (!this.$v.form.$invalid) {
        const comment = this.form.message;
        const docId = this.form.selectedDocumentId

        this.$store.dispatch('updateEnrolmentDocument', {
          comment,
          newStatus: 'declined',
          docId
        }).then(() => {
          this.$bvModal.hide('modal-center');
          this.form.message = '';
          this.form.selectedDocumentId = ''
          this.submitted = false
        })


      }

    },

    formatStatusDisplay(value){
      if(value === "in-progress"){
        return "No service selected"
      }else if(value === "service-selected"){
        return "Service selected";
      }
      return value;
    },

    onVerifyBtnTapped(documentId, documentName){

        let name = this.$options.filters.serviceType(documentName);
        let $this = this;
        confirm("Mark " + name + " as verified", function (){

          $this.$store.dispatch('updateEnrolmentDocument', {
            docId: documentId,
            newStatus: "verified",
            comment: "Completed"
          });

        })


    },
    onDeclineBtnTapped(documentId, documentName){
        this.$bvModal.show('modal-center');
       this.form.selectedDocumentId = documentId;
    },
    generateEnrolmentFileDownloadToken(docId){
      this.$store.dispatch('generateEnrolmentFileDownloadToken', docId).then((token) => {
        console.log("token: " , token)
          if(token) {
            const userId = this.$store.getters.GET_USER_INFO.id;
            window.open(process.env.VUE_APP_API_BASE_URL + "/enrolment/download-prospective-driver-document/"+token+"/"+userId, "_blank");
          }

      })
    },

    onViewFileDetailsTapped(docId){
      const files = this.$store.getters.GET_SELECTED_PROSPECTIVE_DRIVER_FILES;
      this.selectedFile = files.find(el => el.id === docId);
      this.$bvModal.show('file-info-modal');
    },

    onRemoveFileBtnTapped(docId, documentName){

      const name = this.$options.filters.serviceType(documentName);
      confirm('Permanently delete ' + name, () => {
        this.$store.dispatch('removeEnrolmentDocument', docId);
      })

    },
    fileLimitExceeded(file){
      this.$refs.file.removeFile(file);
    },

    handleAddNewDocumentSubmitted(){
      this.addDocumentForm.submitted = true
      this.$v.addDocumentForm.$touch();

      if(this.$v.addDocumentForm.$invalid){
        console.log('form is invalid')
        return;
      }


      const files = this.$refs.file.getAcceptedFiles();

      console.log('files: ', files)

      if(files.length < 1){
        this.$store.dispatch('error', {
          message: 'Please select a file',
          showSwal: true
        })
        return;
      }

      const file = files[0];

      const formData = new FormData()
      formData.append('file_type', this.addDocumentForm.fileType);
      formData.append('file',file)

      /// submit form to the server
      this.$store.dispatch('addNewDriverEnrolmentDocument', {
        userId: this.driver.id,
        formData
      }).then(() => {
        this.addDocumentForm.submitted = false
        this.addDocumentForm.fileType = ''
        this.$refs.file.removeAllFiles();
      })


    },

    markOnBoardingAsCompleted(){
      confirm('You are about to confirm that ' + this.driver.name + ' has completed the on boarding process', () => {
        this.$store.dispatch('markAsCompleted', this.driver.id );
      })
    },

    handleAddOrUpdateDriverDetails(){
      const formData = new FormData();
      formData.append('dossier', this.form2.dossier)
      formData.append('service', this.form2.service)

      const payload = {
        userId: this.driver.id,
        formData: formData
      }
      this.$store.dispatch('addOrUpdateEnrolmentDetails', payload);
    },


  },
  watch: {
    driver: function (d) {
      this.driverAccount = d.driver;
      this.prospectiveDriverAccount = d.prospective_driver;

      console.log('driverAccount changed: ' , this.driverAccount)
      console.log('prospectiveDriverAccount changed: ' , this.prospectiveDriverAccount)

      if(this.driverAccount){
        this.form2.dossier = this.driverAccount.dossier;
      }
      if(this.prospectiveDriverAccount){
        this.form2.service = this.prospectiveDriverAccount.service_type;
      }
    }
  },

  created() {
    const id = this.$route.params.id;
    this.fetchProspectiveDriverDetail(id);
  },
  middleware: "router-auth",
};
</script>
